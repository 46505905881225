import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { WagmiConfig, createConfig, configureChains, mainnet, sepolia } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { ConnectKitProvider } from 'connectkit';
import { getDefaultConfig } from 'connectkit'

import './global.css';
import { defineChain } from 'viem';

const walletConnectProjectId = '963845f9012c5c20580c5c770c4ef630'

const mainChain = defineChain({
  id: 1,
  network: 'homestead',
  name: 'Ethereum',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    alchemy: {
      http: ['https://eth-mainnet.g.alchemy.com/v2'],
      webSocket: ['wss://eth-mainnet.g.alchemy.com/v2'],
    },
    infura: {
      http: ['https://mainnet.infura.io/v3'],
      webSocket: ['wss://mainnet.infura.io/ws/v3'],
    },
    default: {
      http: ['https://ethereum-rpc.publicnode.com'],
    },
    public: {
      http: ['https://ethereum-rpc.publicnode.com'],
    },
  },
  blockExplorers: {
    etherscan: {
      name: 'Etherscan',
      url: 'https://etherscan.io',
    },
    default: {
      name: 'Etherscan',
      url: 'https://etherscan.io',
    },
  },
  contracts: {
    ensRegistry: {
      address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
    },
    ensUniversalResolver: {
      address: '0xc0497E381f536Be9ce14B0dD3817cBcAe57d2F62',
      blockCreated: 16966585,
    },
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 14353601,
    },
  },
})

export const config = createConfig(
  getDefaultConfig({
    chains: [mainChain],
    autoConnect: true,
    appName: 'DuckDAO Access',
    walletConnectProjectId,
  })
)

// const client = createClient(
//   getDefaultClient({
//     appName: 'DuckDAO Access',
//     //infuraId: process.env.REACT_APP_INFURA_ID,
//     //alchemyId:  process.env.REACT_APP_ALCHEMY_ID,
//     chains: [chain.mainnet, {
//       name: 'Localhost',
//       id: 31337,
//       network: 'localhost',
//       rpcUrls: {
//         default: 'http://localhost:8545',
//       },
//     }]
//   })
// );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <WagmiConfig config={config}>
      <ConnectKitProvider theme="auto">
        <App />
      </ConnectKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
